import { ApiResponse } from '../models/ApiResponse';
import {
  ClientContactBulkDeleteRequest,
  ClientContactFilter,
  ClientContactRequest,
  ClientContactResponse,
  ClientContactUpdateRequest,
} from '../models/ClientContacts';
import BaseService from './BaseService';

export default class ClientContactService extends BaseService {
  public static createContact(clientId: string, contact: ClientContactRequest): Promise<ApiResponse<ClientContactResponse>> {
    return this.post(`/v1/clients/${clientId}/contacts`, contact);
  }

  public static updateContact(clientId: string, contact: ClientContactUpdateRequest): Promise<ApiResponse<ClientContactResponse>> {
    return this.put(`/v1/clients/${clientId}/contacts/${contact.id}`, contact);
  }

  public static getAllPaged(clientId: string, filter: ClientContactFilter): Promise<ApiResponse<ClientContactResponse[]>> {
    return this.get(`/v1/clients/${clientId}/contacts/paged`, { params: filter });
  }

  public static getContact(clientId: string, id: string): Promise<ApiResponse<ClientContactResponse>> {
    return this.get(`/v1/clients/${clientId}/contacts/${id}`);
  }

  public static getFilterValues(clientId: string, field: string): Promise<ApiResponse<string[]>> {
    return this.get(`/v1/clients/${clientId}/contacts/filter-values`, { params: { field } });
  }

  public static deleteContact(clientId: string, contactId: string): Promise<ApiResponse<boolean>> {
    return this.delete(`/v1/clients/${clientId}/contacts/${contactId}`);
  }

  public static restoreContact(clientId: string, contactId: string): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/clients/${clientId}/contacts/${contactId}/restore`);
  }

  public static bulkDelete(clientId: string, filter: ClientContactBulkDeleteRequest): Promise<ApiResponse<boolean>> {
    return this.post(`/v1/clients/${clientId}/contacts/bulk-delete`, filter);
  }
}
